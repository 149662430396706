.frame-type-dakosysitethemes_teaserfilter {
    margin-right: auto;
    margin-left: auto;
    padding: 100px 15px;
    text-align: center;
    header {
        
        margin-bottom: 50px;
    }
    #menu-filter li a{
        cursor: pointer;
        //display: block;
        border: 1px solid $blue;
        color: $blue;
        //border-radius: 0px;
        font-family: 'IBM Plex Mono Regular';
        background-color: #fff;
        letter-spacing: 0.03em;
        //margin-bottom: 5px;
        //font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        padding: 9px 19px;
        transition: all 0.5s;
        &:hover, &.active {
            background-color: $blue;
            color: #fff;
            text-decoration: none;
        }
    }
    #mobile-menu-filter-button {
        display: none;
    }
}
.filter-result {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
}
.dak-teaser {
    background-color: $green;
    padding: 30px;
    width: 330px;
    margin: 7px;
    height: 250px;
}