.dak-page-footer {
    div{
        &:first-child{
            padding-bottom: 120px;
        }
    }
    a {
        color: $page-footer-color !important;
    }

    &__brand {

        path {
            fill: $page-footer-color;
        }
    }

    &__socialmedia {
        margin-right: 40px;
        li {
            margin-left: 10px;
            margin-right: 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        i {
            &::before {
                font-size: 22px;
            }
        }
    }

    &__legal {
        li {
            margin-left: 20px;
            margin-right: 20px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__sites {
        ul {
            text-align: right;
        }
        li {
            margin-bottom: 16px;
        }
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
        div {
            &:first-child{
            padding-bottom: 40px;
            }
        }
        &__socialmedia {
            margin-right: 0px;
            padding-bottom: 40px;
        }
        &__brand {
            padding-bottom: 40px;
        }

        &__legal {
            li {
                margin-left: 15px;
                margin-right: 15px;
            }
        }
        &__sites {
            ul {
                text-align: center;
            }
        }
    }
}
