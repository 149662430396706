.dak-magazine-home {
    &__header {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    @include media-breakpoint-up(md) {
        &__header {
            padding-top: 160px;
            padding-bottom: 160px;
        }
    }

    &__count {
        text-align: center;
        line-height: 80px;
    }
}
