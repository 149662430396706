.w-20 {
    width: 20%;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.w-80 {
    width: 80%;
}

.w-100 {
    width: 100%;
}
