.dak-article-stage {
    text-align: center;
    &--fixed {
        height: 460px !important;
        position: relative !important;
        @include media-breakpoint-only(sm) {
            height: 360px !important;
        }
    }
    &__background-image {
        color: $white;
        background-size: cover;
        background-position: center;
    }

    &__layout-2 {
        .dak-article-stage__image--circle {
            width: 80px;
            height: 80px;
        }
    }

    @include media-breakpoint-only(sm) {
        &__content-wrapper {
            padding-top: 260px !important;
        }
        &--fixed {
            .dak-article-stage__content-wrapper {
                padding-top: 60px !important;
            }
        }
    }

    @include media-breakpoint-up(md) {
        position: relative;
        height: calc(100vh - #{$dak-page-header-height-lg + $dak-breadcrumb-height});

        &__content-wrapper {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
}
