.dak-mega {
    &-button {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        &__item {
            a {
                word-break: break-word;
                @media (max-width: 500px) {
                    justify-content: space-between !important;
                }
            }
            &:first-child {
                &.dak--style-1 {
                    background-color: $petrolShade;
                }
                &.dak--style-2 {
                    background-color: $greenShade;
                }
            }
           &:only-child {
            &.dak--style-1 {
                background-color: $petrol;
            }
            &.dak--style-2 {
                background-color: $green;
            }
           }
        }
    }
    &-link-list {
        &__items {
            padding-left: 0px;
            display: flex;
            flex-wrap: wrap;
        }
        &__item {
            padding-right: 15px;
            padding-left: 15px;
            text-align: center;
            @include media-breakpoint-up(md) {
                padding-right: 30px;
                padding-left: 30px;
            }
        }
        &__kicker {
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        &__icon {
            @include media-breakpoint-down(sm) {
                margin-top: 5px;
                i::before {
                    font-size: 30px;
                }
            }
        }
    }
}