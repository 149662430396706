.dak-text {
    &__image img {
        width: 60px;
        height: auto;
    }

    @include media-breakpoint-up(md) {
        &__bodytext {
            h1, h2, h3, h4, h5, blockquote {
                margin-right: -8.25vw;
            }
        }
    }

    &__additional-text {
        @include media-breakpoint-down(md) {
            header {
                width: 100%;
            }
        }
        .dak--style-1 {
            a {
                color: $green !important;
            }
        }
        .dak--style-2,
        .dak--style-3,
        .dak--style-4 {
            a {
                color: $red !important;
            }
        }
    }

    &--layout-1 {
        .dak-text {
            &__additional-text {
                margin-top: -30px;

                .wrapper {
                    padding-left: 30px !important;
                }
            }

            @include media-breakpoint-up(md) {
                &__lead-in {
                    .col {
                        margin-left: 8.3333333333%;
                    }
                }

                &__additional-text {
                    margin-top: -50px;

                    .wrapper {
                        padding-left: inherit;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                &__additional-text {
                    margin-top: -140px;
                }
            }
        }
    }

    &--layout-2 {
        .dak-text {
            &__additional-text {
                padding-top: adaptive-spacer(lg, a);

                @include media-breakpoint-only(sm) {
                    padding-top: adaptive-spacer(sm, a);
                }
            }
        }
    }

    &--layout-3,
    &--layout-4,
    &--layout-5 {
        .dak-text {
            &__text-container > .row,
            &__lead-in > .row {
                justify-content: center;
            }

            &__lead-in {
                text-align: center;
                & > .row > .col {
                    margin-left: 0 !important;
                }
            }

            &__bodytext {
                margin-left: 0 !important;
            }
        }
    }
}
