.login-form, .recovery-form, .register-form {
    input {
        background-color: $white;
        margin-bottom: 10px;
        //height: 48px;
        //font-size: 14px;
        &.powermail_submit {
            background-color: #05507b;
            border-radius: 0px;
            font-family: "Sofia Pro Bold";
            border: none;
            font-weight: 400;
            padding: 10px 30px;
            font-size: 16px;
        }
        &[type="checkbox"] {
            margin-left: 0px !important;
        }
    }
    .back-to-form {
        input {
            background-color: transparent; 
            border: none; 
            color: $white;
        }
        i.dak-icon-arrow-right {
            transition: all 0.2s ease-in-out;
        }
        &:hover {
            i.dak-icon-arrow-right {
                margin-right: -10px;
                margin-left: 10px;
            }
        }
    }

    .tx-srfeuserregister-pi1-error {
        padding-top: 0px !important;
    }
}
.login-form, .recovery-form {
    input {
        height: 48px;
        font-size: 14px;
    }
}
img.registrationprocess {
    height: 400px;
    @include media-breakpoint-down(sm) { 
        display: none;
    }
}
.register-form {

    @include media-breakpoint-down(sm) {

        input.powermail_submit {
            float: none;
        }
    }
}