.dak-text-col-container {
    &.dak--style-1 {
        .dak-text-col-container {
            &__item-header {
                a {
                    color: $white;
                }
            }
        }
    }

    &.dak--style-2,
    &.dak--style-3,
    &.dak--style-4 {
        .dak-text-col-container {
            &__item-header {
                a {
                    color: $blue;
                }
            }
        }
    }

    &__item-header {
        a {
            &:hover {
                text-decoration: none;
            }

            &.collapsed {
                i {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__header,
    &__bodytext,
    &__lead-in {
        text-align: center;
    }

    &__icon {
        height: 60px;
        width: 60px;

        img {
            max-width: 60px;
            max-height: 60px;
            width: auto;
            height: auto;
        }
    }
    &__collapse--links {
        a {
            color: $red;
        }
        a:first-child {
            color: $blue;
        }
    }

    @include media-breakpoint-up(lg) {
        &__item-header {
            a {
                cursor: default;
            }
        }

        &__collapse-icon {
            display: none;
        }

        &__collapse {
            display: block !important;
        }
        &__icon {
            img {
                max-width: 80px;
                max-height: 80px;
            }
        }
    }

}
