@each $style-name, $style in $text-styles {

    .dak-text--#{$style-name} {
        @each $style-property, $value in $style {
            @if type-of($value) != 'map' {
                #{$style-property}: $value;
            }
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        $font-sizes: map-get($style, 'font-sizes');
        @if map_has_key($font-sizes, $breakpoint) {
            $font-size: map-get($font-sizes, $breakpoint);
            @include media-breakpoint-up($breakpoint) {
                .dak-text--#{$style-name} {
                    font-size: $font-size;
                }
            }
        }
    }

    $variants: map-get($style, 'variants');
    @each $variant, $variant-properties in $variants {
        .dak-text--#{$style-name}.dak-text--#{$variant},
        .dak-text--#{$style-name} .dak-text--#{$variant} {
            @each $property, $value in $variant-properties {
                @if type-of($value) != 'map' {
                    #{$property}: $value;
                }
            }
        }
    }
}

