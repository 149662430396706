.news {
    background-color: $gray;
    .news-list-view {
        .header {
            width: 66%;
        }
        .page-navigation {
            text-align: center;
            > p {
                display: none;
            }
            ul {
                display: inline-flex;
                list-style: none;
                li {
                    border-right: 1px solid $blue;
                    padding: 10px 15px;
                    &.current {
                        color: $red;
                        font-weight: 900;
                    }
                    a {
                        color: $blue;
                    }
                    &.previous {
                        padding-top: 5px;
                        padding-bottom: 5px;
                        align-self: center;
                        position: relative;
                        a {
                            &:before {
                                content: '\e808';
                                font-size: 20px;
                                font-family: "fontello";
                            }
                        }
                    }
                    &.last {
                        border: none;
                    }
                    &.next {
                        position: relative;
                        &:not(.current) {
                            padding-top: 5px;
                            padding-bottom: 5px;
                            align-self: center;
                            a {
                                &:after {
                                    content: '\e809';
                                    font-size: 20px;
                                    font-family: "fontello";
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .article {
        background-color: $white;
        &:after {
            display: none !important;
        }
        a {
            color: $blue;
            @include media-breakpoint-down(sm) {
                i {
                    &::before {
                        font-size: 31px;
                    }
                }
            }
        }

        .kicker {
            font-family: "IBM Plex Mono Regular";
            font-weight: normal;
            line-height: 1.57;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }
    .contact {
        background-color: $green;
        word-break: break-word;
        p {
            margin: 0;
            &.dak-text--link-icon {
                color: $red;
            }
        }
        a {
            font-family: $font-family-sofia-pro-bold;
            color: $red !important;
        }
        @include media-breakpoint-down(sm) {
            margin-right: 0px !important;
        }
    }
    @include media-breakpoint-down(sm) {
        &.news-single {
            margin-right: 20px !important;
            margin-left: 20px !important;
            .row {
                margin-left: 0px !important;
            }
            .contact {
                margin-left: 0px !important;
                margin-right: 0px !important;
                margin-top: 30px;
            }
            .article .container-fluid {
                padding-left: 0px !important;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        &.news-single {
            .header, .teaser-text, .news-text-wrap, .news-related-wrap {
                width: 66%;
            }
        }
    }
    @media (max-width: 1200px) and (min-width: 769px){
        .col-xl-9 {
            flex: 0 0 55%;
            max-width: 55%;
        }
        .col-xl-3 {
            flex: 0 0 45%;
            max-width: 45%;
        }
    }
}
