.dak-teaser-card-item {
    &--style-1 {
        background-color: $white;
        a {
            display: block !important;
        }
    }

    &--image,
    &--image-circle,
    &--title,
    &--image-small {
        min-height: 200px;
    }
    &--image-small {
        @include media-breakpoint-only(md) {
            .dak-teaser-card-item__content {
                width: 80% !important;
            }
        }
    }

    &__categories {
        width: 100%;
    }
    &__category-title {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    &.dak--style- {
        &0 {
            .dak-teaser-card-item__category-title {
                background-color: $whiteShade;
            }
        }
        &1 {
            .dak-teaser-card-item__category-title {
                background-color: $petrolShade;
            }
        }
        &2 {
            .dak-teaser-card-item__category-title {
                background-color: $greenShade;
            }
        }
        &3 {
            .dak-teaser-card-item__category-title {
                background-color: $grayShade;
            }
        }
    }
    &.dak--style-1 {
        a {
            .dak--style-0 {
                color: $white;
            }

            i {
                color: $white;
            }
        }
    }

    &.dak--style-2,
    &.dak--style-3,
    &.dak--style-4 {
        a {
            .dak--style-0 {
                color: $blue;
            }

            i {
                color: $blue;
            }
        }
    }
    &--image-bg {
        .dak-teaser-card-item__content {
            background-color: transparent !important;
        }
        .dak-teaser-card-item__image {
            img {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
    }

    &--image {
        @include media-breakpoint-only(md) {
            display: flex;
            .dak-teaser-card-item__image {
                height: 100%;
            }
            .dak-teaser-card-item__content {
                width: 66%;
                .dak-teaser-card-item__icon {
                    background-color: transparent !important;
                }
            }
            a{
                width: 100%;
            }
        }
        @include media-breakpoint-up(md) {
            i {
                color: #fff !important;
            }
        }
    }

    a {

        i.fa-arrow-right {
            transition: all 0.2s ease-in-out;

            &::before {
                font-size: 43px;
            }
        }

        &:hover {
            text-decoration: none;

            i.fa-arrow-right {
                margin-right: -10px;
                margin-left: 10px;
            }
        }
    }

    &__image {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center;

        &--circle {
            width: 80px;
            height: 80px;
        }
        &--small {
            width: 120px;
            height: 120px;
        }
    }

    &__content {
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        // teaser with image circle (tablet)
        &--style-1 {
            .dak-teaser-card-item {
                &__image--circle {
                    margin-bottom: 0 !important;
                }
            }
        }
        .dak-teaser-card-item {
            &__title a {
                display: inline;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-left: 0 !important;
        padding-right: 0 !important;

        // teaser with image circle (smartphone)
        &--style-1 {
            .dak-teaser-card-item {

                &__image--circle {
                    margin-bottom: 60px !important;
                }

                &__text {
                    padding-top: 0 !important;
                }
            }
        }
        &.dak-teaser-card-item--image {
            .dak-teaser-card-item__image {
                img {
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                }
            }
        }
        &--image-bg {
            min-height: 300px;
            .dak-teaser-card-item__image {
                display: none;
            }
            .dak-teaser-card-item__content{
                display: flex;
                width: 100%;
                min-height: 300px;
                background-size: cover;
                background-position: center;
                > div {
                    align-self: flex-end;
                }
            }
        }

        a {

            i {
                &::before {
                    font-size: 31px;
                }
            }
        }
    }

    // only for 66% teaser on tablets
    @include media-breakpoint-only(md) {
        &.col-lg-8 {
            height: 600px;
            a {
                display: block;
            }
            &.dak-teaser-card-item--image {
                a {
                    position: relative;
                    .dak-teaser-card-item__content {
                        position: absolute;
                        bottom: 0px;
                    }
                }
            }
            .dak-teaser-card-item__image
            {
                width: 100%;
                height: 600px;
            }
        }
        &--image-bg {
            height: 400px;
            .dak-teaser-card-item__image{
                width: 100%;
                height: 400px;
            }
            .dak-teaser-card-item__content{
                position: absolute;
                bottom: 0px;
            }
        }
    }

    @include media-breakpoint-up(md) {

        > a {
            display: flex;
        }

        &__image {
            width: 33%; //260px;

            img {
                display: none;
            }
        }
        &--image-small {
            //height: 400px !important;
            .dak-teaser-card-item__content{
                position: absolute;
                bottom: 0px;
            }
        }
    }

    @include media-breakpoint-up(lg) {

        height: 600px;
        &.col-lg-2 {
            max-width: 20%;
            flex: 0 0 20%;
        }
        > a {
            display: block;
            position: relative;
            height: 100%;
        }

        &__image {
            width: 100%;
            height: 600px;
        }

        &__content {
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        &__text {
            width: 340px;
            max-width: 340px;
        }

        &__icon {
            background-color: transparent !important;
        }
    }
    @include media-breakpoint-down(lg) {
        &--title a {
            display: block !important;
        }
    }

    // News
    &--news {
        overflow: hidden;

        .dak-teaser-card-item {
            @include media-breakpoint-only(md) {
                &__social-row {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: -15px;
                    margin-left: -15px;
                }

                &__social-col-md-6 {
                    flex: 0 0 50%;
                    max-width: 50%;
                    position: relative;
                    width: 100%;
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }

            &__body a,
            &__body a i {
                height: auto;
                color: $white !important;
            }

            &__header {
                padding-top: 20px;
                padding-bottom: 20px;
                background: rgba(255, 255, 255, 0.1);
            }

            &__news-link {
                display: block;
            }

            &__social-header {
                margin-bottom: 20px;
            }

            &__social-brand {
                display: inline-flex;
                width: 50px;
                height: 50px;
                border: 1px solid #b3b3b3;
                padding: 4px;
                margin: 0;

                svg path {
                    fill: $red;
                }
            }

            &__social-icon ::before {
                font-size: 22px;
                color: $blue;
            }
        }
    }
}

// Reduce height
.dak-teaser-card-container--layout-1 {
    .dak-teaser-card-item {
        @include media-breakpoint-up(lg) {
            height: 400px;

            &__image {
                height: 400px;
            }
        }
    }
}
