.dak-page-header {
    position: fixed;
    top: 0;
    background: $white;
    z-index: 2010;
    //display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    ul {
        margin-bottom: 0;
    }

    & ~ .dak-page-content {
        padding-top: #{$dak-page-header-height-lg};

        @media (max-width: '500px') {
            padding-top: 54px;
        }
    }
}
