.dak-teaser-card-stage {
    &__header {
        color: $white;
    }

    &__background-wrapper {
        background-size: cover;
        background-position: center;
    }

    &__content {
        width: 100%;
        background: rgba(255, 255, 255, 0.1);
        color: $green;
    }

    @include media-breakpoint-down(sm) {
        &__header {
            margin-bottom: 100px !important;
        }

        &--sidecard {
            .dak-teaser-card-stage {
                &__cards {
                    margin-right: -30px;
                    margin-left: -30px;

                    article {
                        &:last-child {display: none;}
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {

        &__background-wrapper {
            height: calc(100vh - #{$dak-page-header-height-lg});
        }

        &__container {
            top: #{$dak-page-header-height-lg};
            position: absolute;
            height: calc(100vh - #{$dak-page-header-height-lg});
            background-image: none !important;

            ~ .dak-teaser-card-item {
                display: none;
            }
        }

        &__cards {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        .dak-teaser-card-item {
            margin-bottom: 0 !important;

            a {
                position: unset !important;
                height: auto !important;
            }
        }
    }

    @include media-breakpoint-up(lg) {

        &__header {
            margin-right: 33.333333334%;

            h1 {
                width: 80%;
            }
        }

        &--sidecard {
            margin-right: 33%;
        }

        &__content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        .dak-teaser-card-item {
            height: auto;

            &__image {
                height: calc(100vh - #{$dak-page-header-height-lg});
            }
        }
    }
}
