.dak-navbar {
    padding: 0px;
    font-family: "Sofia Pro Bold";
    display: flex;
    justify-content: space-between;
    height: 84px;
    @media (max-width: '500px') {
        height: 54px;
    }
    @include media-breakpoint-up(md) {
        .hide-desktop {
            display: none;
        }
    }
    .dak-nav-item {
        line-height: 84px;
        @media (max-width: '500px') {
            line-height: 54px;
        }
        @media (min-width: '768px') and (max-width: '1010px'){
            font-size: 12px;
        }
        a.nav-link {
            color: $blue;
            &:hover{
                color: $red;
                svg path {
                    fill: $red;
                }
            }
        }
        .dak-dropdown-menu {
            background-color: $lightgray;
            border: none;
            border-radius: 0px;
            @include media-breakpoint-up(md) {
                padding: 30px;
                &.dropdown-language {
                    padding: 20px;
                    .dropdown-item {
                        width: 30px;
                        line-height: 0px !important;
                    }
                }
            }
            margin-top: 0px;
            ul {
                list-style: none;
                padding-left: 0px;
            }
            &.dropdown-search {
                width: 800px;
                padding: 0px;
                left: -746px;
                height: 80px;
                line-height: 16px;
                @media (max-width: '1000px') {
                  width: 600px;
                  left: -546px;
                }
                .input-wrapper {
                    position: relative;
                    display: flex;
                    i {
                        &.dak-icon-search {
                            margin-top: 27px;
                            margin-left: 30px;
                            color: $blue;
                            &::before {
                                font-size: 30px;
                            }
                        }
                    }
                    input {
                        font-size: 26px;
                        color: $blue;
                        border: none;
                        background-color: transparent;
                        padding: 15px;
                        padding-right: 80px;
                        height: 80px;
                        width: 100%;
                        &:focus {
                          outline: none;
                        }
                        &:not(:placeholder-shown) + button[type="submit"] {
                            opacity: 1 !important;
                            color: $blue;
                        }
                        &::-webkit-search-cancel-button {
                            display: none;
                        }
                    }
                    input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus,  input:-webkit-autofill:active{
                        -webkit-box-shadow: 0 0 0 40px $lightgray inset !important;
                    }
                    input:-webkit-autofill{
                        -webkit-text-fill-color: $blue !important;
                    }
                    button[type="submit"] {
                        border: none;
                        background-color: transparent;
                        opacity: 0.4;
                        position: absolute;
                        right: 20px;
                        top: 20px;
                        &:hover {
                            opacity: 1;
                        }
                        i.dak-icon-arrow-right:before {
                            font-size: 40px;
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        background-color: $white;
                        input {
                            font-size: 20px;
                        }
                    }
                }
            }
            &.dropdown-login {
                width: 400px;
                left: -348px;
                font-family: 'IBM Plex Mono Regular';
                line-height: 16px;

                p {
                    font-size: 14px;
                    letter-spacing: 1px;
                    color: gray;
                    margin-bottom: 16px;
                }
                a {
                    font-size: 12px;
                    line-height: 26px;
                    color: $blue;
                    &:hover {
                        text-decoration: underline;
                    }

                }
                input.form-control {
                    height: 48px;
                    background-color: $white;
                    border: none;
                    width: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                    margin-bottom: 16px;
                    font-size: 12px;
                    &:focus {
                        outline: $blue;
                        color: $blue;
                        font-weight: bold;
                        border: 1px solid $blue;
                    }
                }
                .form-group {
                    svg {
                        position: absolute;
                        color: $lightgray;
                        right: 20px;
                        &#passwordHidden {
                            top: 20px;
                        }
                        &#passwordVisible {
                            top: 17px;
                        }
                    }
                }
                .form-check {
                    label {
                        font-size: 12px;
                        vertical-align: middle;
                        color: $blue;
                    }
                    input {
                        margin-right: 10px;
                        margin-left: 5px;
                        transform: scale(1.7);
                    }
                }
                .username {
                    color: $red;
                    font-weight: 700;
                }
                .account {
                    font-family: 'Sofia Pro Bold';
                    font-weight: 700;
                    font-size: 16px;
                }
                .btn-submit {
                    background-color: $petrol;
                    color: $white;
                    font-family: 'Sofia Pro Bold';
                    border-radius: 0;
                    border: none;
                    width: 100%;
                    //margin-bottom: 20px;
                    margin-top: 16px;
                    padding-top: 16px;
                    padding-bottom: 16px;
                    i.dak-icon-arrow-right {
                        color: #d1ff7e;
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
                        &::before {
                            font-size: 14px;
                        }
                    }
                    span {
                        transition: all 0.4s ease-in-out;
                    }
                    &:hover {
                        i.dak-icon-arrow-right {
                            margin-right: -5px;
                            margin-left: 5px;
                            opacity: 1;
                        }
                        span {
                            margin-right: 10px;
                        }
                    }
                }
            }
            .dropdown-item {
                padding: 8px 0px;
                line-height: 20px;
                color: $blue;
                white-space: break-spaces;
                word-wrap: break-word;
                &.active, &:hover {
                    background-color: transparent;
                    color: $red;
                }
                &:focus {
                    background-color: transparent;
                }
            }
        }
    }
    &__brand {
        path {
            fill: $red;
        }
        @media (max-width: '780px') {
            width: 130px;
            height: auto;
        }
        @media (max-width: '500px') {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }
    }
    &__content {
        display: flex;
        flex-direction: row-reverse;
        @include media-breakpoint-down(sm) {
            display: contents;
        }
    }
    &__icons {
        .dak-nav-link {
            padding: 0px 12px;
            svg {
                margin-bottom: 3px;
                &#search-icon--close, &#login-icon--close {
                    display: none;
                    margin-right: -20px;
                }
                &.red-border {
                    box-shadow: 0 0 0 2px $red;
                    border-radius: 50%;
                    padding: 2px;
                }
            }
        }
        @include media-breakpoint-down(sm) {
            margin-left: auto;
            .dak-dropdown {
                position: initial;
                .dak-dropdown-menu {
                    position: absolute;
                    width: -webkit-fill-available !important;
                    width: -moz-available !important;
                    width: fill-available !important;
                    margin-left: -20px;
                    margin-right: -20px;
                    left: 0px !important;
                    &.dropdown-search {
                        height: 200px;
                        form {
                            padding: 60px 20px;
                            input {
                                background-color: #FFFFFF;
                                font-size: 20px;
                            }
                        }
                    }
                    &.dropdown-login {
                        padding: 30px;
                        .login-wrapper {
                            max-width: 340px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        .dak-dropdown-language {
            @include media-breakpoint-down(sm) {
                display: none;
            }
            .dak-nav-link {
                font-size: 16px;
            }
            .dropdown-language {
                min-width: fit-content;
                left: -15px;
            }
        }
        .dropdown-login {
            #passwordVisible {
                display: none;
            }
        }

    }
    &__mobile {
        display: none;
        margin-left: auto;
        @include media-breakpoint-down(sm) {
            display: block;
            .dak-dropdown {
                position: initial;
                .dak-dropdown-menu {
                    position: absolute;
                    width: -webkit-fill-available !important;
                    margin-left: -20px;
                    margin-right: -20px;
                    left: 0px !important;
                    &.dropdown-search {
                        height: 200px;
                        form {
                            padding: 60px 30px;
                            input {
                                background-color: #FFFFFF;
                                font-size: 20px;
                            }
                        }
                    }
                    &.dropdown-login {
                        max-height: 445px;
                        padding: 60px;
                        .login-wrapper {
                            max-width: 340px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        @media (max-width: '500px') {
            .dropdown-login {
                padding: 30px !important;
            }
        }

        .dak-navbar-nav {
            flex-direction: row;
            .dak-nav-item {
                padding: 0 15px;
                @media (max-width: '500px') {
                    line-height: 54px;
                }
                &:last-of-type {
                    padding-right: 30px;
                }
                .dak-nav-link{
                    padding: 0;
                    i {
                        vertical-align: text-top;
                    }
                }
            }
        }
    }
    &__toggler {
        padding: 0px;
        @include media-breakpoint-up(md) {
            display: none !important;
        }
        .navbar-toggler-icon {
            height: auto;
        }
        &[aria-expanded="false"] {
            #navbar-toggler-icon--open {
                display: block;
            }
            #navbar-toggler-icon--close {
                display: none;
            }
        }
        &[aria-expanded="true"] {
            #navbar-toggler-icon--open {
                display: none;
            }
            #navbar-toggler-icon--close {
                display: block;
            }
        }
    }

    &__collapse {
        @include media-breakpoint-down(sm) {
            overflow-y: auto;
            height: 100vh;
            background-color: $lightgray;
            margin-left: -20px;
            margin-right: -20px;
            padding-top: 60px;
            .dak-navbar-nav {
                a, p {
                    padding-left: 30px !important;
                    padding-right: 30px !important;
                }
                .dropdown-on-click {
                    .dropdown-language {
                        display: flex;
                        background-color: transparent !important;
                        .dropdown-item {
                            width: auto;
                            padding-left: 15px !important;
                            padding-right: 15px !important;
                            box-shadow: 0px 0px 0px !important;
                        }
                    }
                }
            }
        }
        .dak-navbar-nav {
            margin-left: auto;
            .dak-dropdown-language {
                line-height: 0px;
                @include media-breakpoint-up(md) {
                    display: none;
                }
                display: inline-flex;
                padding-top: 10px;
                padding-left: 15px;
                .dak-nav-link {
                    line-height: 32px;
                    padding-right: 15px !important;
                    padding-left: 15px !important;
                    color: $red;
                    svg {
                        display: none;
                    }
                }
                .dak-dropdown-menu {
                    .dropdown-item {
                        line-height: 0px;
                        width: 63px;
                        padding-left: 15px !important;
                        padding-right: 15px !important;
                    }
                }
            }
            .dropdown-on-hover {
                .dak-dropdown-menu{
                    p {
                        font-family: 'IBM Plex Mono Regular';
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-size: 14px;
                        color: gray;
                        line-height: 16px;
                        margin-bottom: 30px;
                    }
                    @include media-breakpoint-down(sm) {
                        .level-2--nav {
                            display: none;
                            background-color: $gray;
                            padding-bottom: 30px;
                        }
                        .level-1--column:not(:last-child) p {
                            box-shadow: inset 0px 0px 0px #DCDCDC;
                        }
                        .level-1--column p {
                            padding: 17px 0px;
                            margin-bottom: 0px;
                        }
                    }
                    @media (min-width: '769px') {
                        &.show {
                            display: flex !important;
                        }
                        .level-1--column {
                            width: 240px;
                            &:not(:last-child) {
                                padding-right: 30px;
                            }
                        }
                    }
                    .level-3--nav {
                        .dropdown-item {
                            font-family: 'IBM Plex Mono Regular';

                        }
                    }

                }
            }
            .dak-nav-link {
                padding: 0px 20px;
                @media (min-width: '768px') and (max-width: '1010px'){
                    padding: 0px 10px;
                }
            }

            .dropdown-on-click {
                .dak-nav-link {
                    padding-right: 0.5rem;
                    padding-left: 0.5rem;
                }
            }


            @include media-breakpoint-down(sm) {
                .dak-dropdown-login, .dak-dropdown-search {
                    display: none;
                }
                .dak-nav-item{
                    line-height: 54px;
                    box-shadow: inset 0px 1px 0px #DCDCDC;
                    font-size: 16px;
                    .dak-nav-link {
                        padding: 0px 30px;
                    }
                    .dak-nav-link, .level-1--column {
                        svg {
                            position: absolute;
                            right: 30px;
                            top: 23px;
                            &.collapsed {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    .dak-dropdown-menu {
                        background-color: #FFFFFF;
                        padding-top: 0px;
                        padding-bottom: 0px;
                        .dropdown-item {
                            box-shadow: 0px 1px 0px #DCDCDC;
                            padding-top: 16px;
                            padding-bottom: 16px;
                        }
                        .level-2--nav .dropdown-item {
                            box-shadow: none;
                            padding-top: 12px;
                            padding-bottom: 12px;
                        }
                    }
                }
            }
        }
    }

}
