@import "variables/colors";
@import "variables/text";

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white;
$body-color:                $blue;


// Links
//
// Style anchor elements.

$link-color:                              $red;
$link-decoration:                         none;
$link-hover-color:                        $red;
$link-hover-decoration:                   none;
