.dak-logo-wall {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    &__item {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 30px;
        @include media-breakpoint-only(sm) {
            padding-right: 15px;
            padding-left: 15px;
        }
        @media (min-width: 321px) and (max-width: 600px) {
            margin-bottom: 15px;
            padding-right: 5px !important;
            padding-left: 5px !important;
        }
        @media (max-width: 320px) {
            margin-bottom: 10px;
            flex-basis: 50%;
            max-width: 50%;
        }
    }
    &__image {
        background-color: #FFFFFF;
        height: 8vw;
        width: 14vw;
        object-fit: scale-down;
        padding: 20px;
        @include media-breakpoint-only(md) {
            height: 13vw;
            width: 22vw;
        }
        @include media-breakpoint-down(sm) {
            height: 17vw;
            width: 28vw;
        }
        @media (max-width: 600px) {
            padding: 10px;
        }
        @media (max-width: 320px) {
            height: 24vw;
            width: 40vw;
        }
    }
    &__items {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}