.dak-image-text-teaser {
    @include media-breakpoint-down(sm) {
        .order-12 {
            order: unset;
        }
    }
    &__kicker {
        letter-spacing: 1;
        text-transform: uppercase;
    }
    &__header {
        word-break: break-word;
    }
}