$font-path: "@packages/dakosy-site-themes/Resources/Public/Fonts/";
$font-size-base: 14px;
$text-styles: () !default;
$headings-margin-bottom: 0;
$paragraph-margin-bottom:   22px;
$border-radius: 0;

$dak-page-header-height-sm: 65px;
$dak-page-header-height-md: 85px;
$dak-page-header-height-lg: $dak-page-header-height-md;


$grid-breakpoints: (
    sm: 0,
    md: 768px,
    lg: 1200px,
    xl: 1680px
);

$grid-gutter-width: 60px;
$grid-gutter-width-sm: 40px;


$adaptive-spacers: (
    a: (
        sm: 60px,
        md: 100px,
        lg: 100px

    ),
    b: (
        sm: 30px,
        md: 60px,
        lg: 60px
    ),
    c: (
        sm: 20px,
        md: 30px,
        lg: 30px
    ),
    d: (
        sm: 10px,
        md: 10px,
        lg: 10px
    ),
    0: (
        sm: 0,
        md: 0,
        lg: 0,
        xl: 0
    ),
);

$responsive-spacers: (
    a: (
        x: 11%,
        y: 11vw
    ),
    b: (
        x: 6.66%,
        y: 6.66vw
    ),
    c: (
        x: 5.5%,
        y: 5.5vw
    )
);

$page-footer-color: $white !default;

// Breadcrumbs
$dak-breadcrumb-height: 42px;
$breadcrumb-font-size:              null;

$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           .5rem;

$breadcrumb-margin-bottom:          0;


$breadcrumb-divider:                quote("►");

$breadcrumb-border-radius:          $border-radius;
