$font-family-sofia-pro-bold: 'Sofia Pro Bold';
$font-family-ibm-plex-mono-regular: 'IBM Plex Mono Regular';
$font-family-ibm-plex-mono-semibold: 'IBM Plex Mono SemiBold';

$font-family-base: $font-family-ibm-plex-mono-regular;

$text-styles: (
    display-0: (
        font-family: $font-family-sofia-pro-bold,
        line-height: 1,
        letter-spacing: normal,
        font-sizes: (
            sm: 55px,
            md: 110px,
            xl: 160px
        ),
    ),
    display-1: (
        font-family: $font-family-sofia-pro-bold,
        line-height: 1,
        letter-spacing: normal,
        font-sizes: (
            sm: 40px,
            md: 60px,
            lg: 70px
        ),
    ),
    display-2: (
        font-family: $font-family-sofia-pro-bold,
        line-height: 1.13,
        letter-spacing: normal,
        font-sizes: (
            sm: 32px,
            md: 46px
        ),
    ),
    display-3: (
        font-family: $font-family-sofia-pro-bold,
        line-height: 1.231,
        letter-spacing: normal,
        font-sizes: (
            sm: 20px,
            md: 26px
        ),
    ),
    copy: (
        font-family: $font-family-ibm-plex-mono-regular,
        font-weight: normal,
        line-height: 1.57,
        letter-spacing: normal,
        font-sizes: (
            sm: 14px
        ),
    ),
    copy-bold: (
        font-family: $font-family-ibm-plex-mono-semibold,
        font-weight: 600,
        line-height: 1.57,
        letter-spacing: normal,
        font-sizes: (
            sm: 14px
        ),
    ),
    roof-line: (
        font-family: $font-family-ibm-plex-mono-regular,
        font-weight: normal,
        line-height: 1.57,
        letter-spacing: 1px,
        text-transform: uppercase,
        font-sizes: (
            sm: 14px
        ),
    ),
    link: (
        font-family: $font-family-sofia-pro-bold,
        font-weight: bold,
        line-height: 1,
        letter-spacing: normal,
        font-sizes: (
            sm: 16px
        ),
    ),
    tiny: (
        font-family: $font-family-ibm-plex-mono-regular,
        font-weight: normal,
        line-height: 1.2,
        letter-spacing: normal,
        font-sizes: (
            sm: 12px
        ),
    ),
    caption: (
        font-family: $font-family-ibm-plex-mono-regular,
        font-weight: normal,
        line-height: 16px,
        letter-spacing: 1px,
        text-transform: uppercase,
        font-sizes: (
            sm: 12px
        ),
    )
)
