.dak-container {
    display: block;
}


section.dak--style-0:not(.dak-image-text-teaser) + section.dak--style-0:not(.dak-image-text-teaser),
section.dak--style-1:not(.dak-image-text-teaser) + section.dak--style-1:not(.dak-image-text-teaser),
section.dak--style-2:not(.dak-image-text-teaser) + section.dak--style-2:not(.dak-image-text-teaser),
section.dak--style-3:not(.dak-image-text-teaser) + section.dak--style-3:not(.dak-image-text-teaser),
section.dak--style-4:not(.dak-image-text-teaser) + section.dak--style-4:not(.dak-image-text-teaser) {
    margin-top: -#{adaptive-spacer(lg, a)};

    @include media-breakpoint-only(sm) {
        margin-top: -#{adaptive-spacer(sm, a)};
    }
}

.dak--style,
.dak--style .dak--style,
.dak--style .dak--style .dak--style {

    &-0 {
        background-color: transparent;

        .dak-text {
            &--primary {
                color: $white;
            }

            &--secondary {
                color: $petrol;
            }
            &--dark * {
                color: $blue !important;
            }
            &--light {
                color: #FFF;
            }
        }
    }

    &-1 {
        background-color: $petrol;
        color: $white;

        a {
            color: $green;
        }

        .dak-text {
            &--primary {
                color: $white;
            }

            &--secondary {
                color: $green;
            }
        }

        .dak-table,
        .dak-rte table {
            border-bottom: rgba(209, 255, 162, 0.3) solid 1px;

            th,
            td {
                border-top: rgba(209, 255, 162, 0.3) solid 1px;
            }
        }

    }

    &-2 {
        background-color: $green;
        color: $blue;

        a {
            color: $red;
        }

        .dak-text {
            &--primary {
                color: $blue;
            }

            &--secondary {
                color: $red;
            }
        }
    }

    &-3 {
        background-color: $gray;
        color: $blue;

        a {
            color: $red;
        }

        .dak-text {
            &--primary {
                color: $blue;
            }

            &--secondary {
                color: $red;
            }
        }
    }

    &-4 {
        background-color: $white;
        color: $blue;

        a {
            color: $red;
        }

        .dak-text {
            &--primary {
                color: $blue;
            }

            &--secondary {
                color: $red;
            }
        }
    }
}

strong {
    font-family: $font-family-ibm-plex-mono-semibold;
    font-weight: 600;
}


.dak-rte {
    li {
        margin-bottom: 10px;
    }
}

.dak-text--link-icon {

    &::after {
        transition: all 0.2s ease-in-out;
        font-style: normal;
        font-variant: normal;
        font-size: 14px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: "Fontello";
        font-weight: 900;
        content: "\e803";
        margin-left: 4px;
        padding-right: 3px;
        white-space: nowrap;
    }

    &:hover {
        &::after {
            margin-left: 7px;
            padding-right: 0;
        }
    }
    &.dak-text--file-link {

        position: relative;
        &::after {
            content: "\e801";
            font-family: "Fontello";
            margin-left: 4px !important;
            position: absolute;
            top: 6px;
        }
        &:hover {
            &::after {
                top: 9px;
            }
        }
    }
}

.dak-rte {
    h1 {
        @extend .dak-text--display-2;
    }

    h2 {
        @extend .dak-text--display-3;
    }

    h3 {
        @extend .dak-text--display-3;
    }

    h1, h2, h3 {
        padding-bottom: 22px;
    }

    blockquote {
        @extend .dak-text--display-3;

        padding-bottom: adaptive-spacer(lg, b);

        @include media-breakpoint-only(sm) {
            padding-bottom: adaptive-spacer(sm, b);
        }
    }

    p + blockquote {
        margin-top: -22px;

        p {
            padding-top: adaptive-spacer(lg, b);

            @include media-breakpoint-only(sm) {
                padding-top: adaptive-spacer(sm, b);
            }
        }
    }
    /*
    p + h1, p + h2, p + h3 {

        padding-bottom: adaptive-spacer(lg, b);

        @include media-breakpoint-only(sm) {
            padding-bottom: adaptive-spacer(sm, b);
        }
    }
    */



    a {
        @extend .dak-text--link;
        @extend .dak-text--link-icon;

        &[target="thePicture"]::after {
            display: none;
        }
    }
}

.dak-table,
.dak-rte table, table.contenttable {
    width: 100%;
    border-bottom: rgba(5, 80, 123, 0.3) solid 2px;
    color: $blue;
    a {
        @extend .dak-text--link;
        @extend .dak-text--link-icon;
        color: $red;
        &::after {
            transition: all 0.2s ease-in-out;
            font-style: normal;
            font-variant: normal;
            font-size: 14px;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: "Fontello";
            font-weight: 900;
            content: "\e803";
            margin-left: 4px;
            padding-right: 3px;
            white-space: nowrap;
        }
        &[href$=".pdf"] {

            &::after {
                transform: rotate(90deg);
                display: inline-block;
            }

            &:hover::after {
                margin-left: 5px;
                margin-right: 2px;
            }
        }
    }
    tr {
        th {
            font-family: $font-family-sofia-pro-bold;
            font-weight: 600;
            font-size: 16px;
            @include media-breakpoint-up(lg) {
                &:first-child {
                    padding-right: 40px;
                }
                font-size: 26px;
            }
            padding-bottom: 25px;
        }
        td {
            padding: 12px 0px;
            vertical-align: top;
            border-top: rgba(5, 80, 123, 0.3) solid 1px;
            strong  {
                font-family: $font-family-sofia-pro-bold;
            }
        }
        &:first-child {
            td {
                border-top: rgba(5, 80, 123, 0.3) solid 2px;
            }
        }
    }
}

// Fix ordering css position bug. More at https://entrados.atlassian.net/browse/DAK-3
@include media-breakpoint-only(sm) {
    .container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
        padding-right: $grid-gutter-width-sm / 2;
        padding-left: $grid-gutter-width-sm / 2;
    }
}

#uc-privacy-button {
    display: none;
}

.extbase-debugger {
    z-index: 9999;
}

.typo3-messages {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.form__message,
.alert {
    padding: 10px;
}

.form__message--error,
.alert-success {
    color: #FF0000 !important;
    border: 2px solid #FF0000;
}

section.dak-teaser-card-container .dak-teaser-card-container__cards:first-child,
section.dak-text-col-container .dak-text-col-container__cols-wrapper:first-child .dak-text-col-container__cols
{
    margin-top: 0px !important;
}

@include media-breakpoint-only(md) {
    section.dak-teaser-card-container .dak-teaser-card-container__cards .dak-teaser-card-item:last-child {
        margin-bottom: 0px !important;
    }

}
@include media-breakpoint-down(md) {
    .dak-text-col-container {
        &__collapse {
            padding-bottom: 0px !important;
            .dak-rte {
                padding-bottom: 30px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .dak-text-col-container {
        &__collapse {
            &.collapse {
                display: block;
        	    height: auto !important;
                visibility: visible;
            }
            &.collapsing{
                position: relative;
                height: unset !important;
                overflow: hidden;
            }
        }
    }
}

#blempfaenger {
    td:first-child {
        font-weight: 700;
        font-family: 'Sofia Pro Bold';
    }
}
