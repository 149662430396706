.dak-use-case {
    &__header {
        text-align: center;
    }
    &__filter {
        line-height: 50px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        &-mobile {
            display: none;
            .custom-select {
                position: relative;
                select {
                    display: none;
                }
                .select-hide {
                    display: none;
                }
                .select-selected {
                    background-color: #FFF;
                    color: $blue;
                    border: 1px solid $blue;
                    padding-right: 12px;
                    padding-left: 12px;
                    cursor: pointer;
                    text-align: left;
                    user-select: none;
                    &:after {
                        position: absolute;
                        content: "\e806";
                        font-family: 'fontello';
                        right: 18px;
                        top: 2px;
                        font-size: 12px;
                    }
                    &.select-arrow-active {
                        background-color: $blue;
                        color: #FFF;
                        &:after {
                            content: "\e807";
                        }
                    }
                }
                .select-items {
                    position: absolute;
                    background-color: #FFF;
                    border: 1px solid $blue;
                    color: $blue;
                    padding-right: 20px;
                    padding-left: 20px;
                    top: 100%;
                    left: 0;
                    right: 0;
                    z-index: 99;
                    div {
                        box-shadow: inset 0px 1px 0px #DCDCDC;
                        cursor: pointer;
                        text-align: left;
                        user-select: none;
                    }
                }
            }
        }
        &-desktop {
            a {
                padding: 10px;
                margin-right: 5px;
                margin-left: 5px;
                border: 1px solid $blue;
                color: $blue;
                cursor: pointer;
                background-color: white;
            }
            a:hover, a.active {
                background-color: $blue;
                color: white;
            }
        }
        @include media-breakpoint-down(sm) {
            &-mobile {
                display: block;
            }
            &-desktop {
                display: none;
            }
        }
    }
    &__items {
        margin-left: -10px;
        margin-right: -10px;
    }

    &__item {
        padding-right: 8px;
        padding-left: 8px;
        &-content {
            background-color: $green;
            margin-bottom: 16px;
            height: 250px;
            margin-right: 0px !important;
            margin-left: 0px !important;
            i {
                height: 40px;
                &:before {
                    line-height: 40px;
                }
            }
            &--link {
                color: $blue;
            }
            &--popup {
                a {
                    padding: 0px;
                    color: $blue !important;
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    display: contents;
                    i.dak-icon-plus {
                        transition: all 0.2s ease-in-out;
                    }
                    &:hover {
                        i.dak-icon-plus {
                            transform: scale(1.2);
                        }
                    }
                }
                &.dak--style-1 a {
                    color: $white !important
                }
            }
        }
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 0px !important;
        }
        &-title {
            padding-right: 10px;
        }
        &-cover {
            padding: 0px !important;
            img {
                height: 80px;
                width: 80px;
                object-fit: contain;
                float: right;
            }
        }
    }
}
