.dak-content-footer-button {
    a {
        i.fa-arrow-left,
        i.fa-arrow-right {
            transition: all 0.2s ease-in-out;

            &::before {
                font-size: 43px;
            }
        }

        &:hover {
            text-decoration: none;

            i.fa-arrow-right {
                margin-right: -10px;
                margin-left: 10px;
            }

            i.fa-arrow-left {
                margin-left: -10px;
                margin-right: 10px;
            }
        }
    }
}
