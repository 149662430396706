.dak-video {
    width: 100%;
    &__header {
        text-align: center;
    }
    > * {
        margin-right: auto;
        margin-left: auto;
        @include media-breakpoint-up(md) {
            width: 66%;
        }

        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }
    &__content {
        .embed-responsive:last-child{
            margin-bottom: 0px !important;
        }
    }
}
