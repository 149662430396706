.dak-stage {
    &__header {
        color: $white;
    }

    &__background-wrapper {
        background-size: cover;
        background-position: center;
    }
    &__content {
        width: 100%;
        background: rgba(255, 255, 255, 0.1);
        color: $green;
    }
    .dak-teaser-card-item {
        padding-right: 20px;
        margin-bottom: auto;
        margin-top: auto;
        &__image--circle {
            display: inline-flex;
            width: 45px;
            height: 45px;
            margin-top: auto;
            margin-bottom: auto;
        }
        &-container {
            header  {
                padding-top: 30px; 
                padding-bottom: 30px; 
                background-color: rgba(255,255,255,.1); 
                width: 100%;
            }
        }
        &-link {
            color: #FFF;
        }
        &__icon {
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    &__teaser {
        background-color: #D1FF7E !important;
        h2 {
            margin-bottom: 20px;
        }
    }
    @include media-breakpoint-only(md) {
        &__background-wrapper {
            height: 600px;
        }
    }
    @include media-breakpoint-down(sm) {
        &__header {
            margin-bottom: 100px !important;
        }
        &__background-wrapper {
            height: 300px;
        }
        &__container {
            min-height: 300px;
        }
        &--sidecard {
            .dak-stage {
                &__cards {
                    margin-right: -30px;
                    margin-left: -30px;

                    article {
                        &:last-child {display: none;}
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(md) {
        &__container {
            top: #{$dak-page-header-height-lg};
            position: absolute;
            height: calc(100vh - #{$dak-page-header-height-lg});
            background-image: none !important;

            ~ .dak-teaser-card-item {
                display: none;
            }
        }
        .dak-teaser-card-item {
            a {
                position: unset !important;
                height: auto !important;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        display: flex !important;
        &__background-wrapper {
            width: 66.6666%
        }
        &__cards {
            position: absolute;
            bottom: 0;
            left: 0;
            top: 85px;
            width: 100%;
        }
        &__header {
            margin-right: 33.333333334%;
        }

        &--sidecard {
            margin-right: 33.333333334%;
        }

        &__content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
        .dak-teaser-card-item {
            height: auto;
            &__icon {
                position: relative;
            }
        }
    }
}
