#headerElements {
    position: fixed;
    z-index: 2011;
    width: 100%;
}
.dak-infobanner {
    position: relative;
    &-body {
        text-align: center;
        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }
    .dak-rte {
        width: 100%; 
        align-self: center;
    }
    &.dak--style-2 {
        svg {
            color: $blue;
            rect {
                fill: $blue;
            }
        }
    }
}
