.dak-accordion-link-list {
    &__header {
        text-align: center;
        margin-bottom: 30px;
    }
    &__item {
        color: $blue !important;
        a {
            color: $red !important;
        }
    }
    .modal-content h2 {
        color: $blue !important;
    }
    &__item-header {

        a, button {
            min-height: 64px;
            height: auto;
            color: $blue !important;
            padding-right: 20px;
            padding-left: 20px;
            &:focus {
                box-shadow: none !important;
            }
        }
    }
    button &__collapse-icon {
        &::before {
            font-size: 13px;
            font-weight: bold;
        }
    }
    a &__collapse-icon {
        &::before {
            font-size: 30px;
        }
    }
    &__item-header, &__collapse {
        background-color: $gray;
    }
    &.dak--style-3 &__item-header, &.dak--style-3 &__collapse  {
        background-color: $white;
    }

    [aria-expanded="true"] &__collapse-icon {
        transform: rotate(180deg);
    }
    &__collapse-content {
        padding: 20px;
        color: $blue !important;
        a {
            color: $red !important;
        }
    }
    &__item-icon {
        height: 32px;
        width: 32px;
        margin-right: 20px;

        img {
            max-width: 32px;
            max-height: 32px;
            width: auto;
            height: auto;
            object-fit: cover;
        }
    }
}
