/* ibm-plex-mono-regular - ext latin */

@font-face {
    font-family: 'IBM Plex Mono Regular';
    font-style: normal;
    font-weight: 400;
    src: url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-regular.eot');
    src: local(''),
    url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-regular.woff2') format('woff2'),
    url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-regular.woff') format('woff'),
    url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-regular.ttf') format('truetype'),
    url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-regular.svg#IBMPlexMono') format('svg');
}

@font-face {
    font-family: 'IBM Plex Mono SemiBold';
    font-style: normal;
    font-weight: 600;
    src: url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-600.eot');
    src: local(''),
    url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-600.woff2') format('woff2'),
    url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-600.woff') format('woff'),
    url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-600.ttf') format('truetype'),
    url('#{$font-path}IbmPlexMono/ibm-plex-mono-v12-latin-ext_latin-600.svg#IBMPlexMono') format('svg');
}
