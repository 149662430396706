.dak-search-results {
    &__kicker {
        font-family: "IBM Plex Mono Regular";
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    form {
        background-color: transparent;
        position: relative;
        input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus,  input:-webkit-autofill:active {
           -webkit-box-shadow: 0 0 0 40px $white inset !important;
        }
        input:-webkit-autofill {
            -webkit-text-fill-color: $blue !important;
        }
        i {
            margin-top: 27px;
            margin-left: 30px;
        }
        label, legend {
            display: none;
        }
        > p {
            display: none;
        }
        .tx-indexedsearch-form {
            position: relative;
            &::before {
                content: '\e810';
                position: absolute;
                font-size: 40px;
                font-family: "fontello";
                margin-left: 20px;
                margin-top: 10px;
            }
            &::after {
                content: '\e803'; 
                position: absolute;
                cursor: pointer;
                margin-left: -50px;
                top: 9px;
                font-size: 40px;
                font-family: "fontello";
            }
        }
        input.tx-indexedsearch-searchbox-sword {
            width: 800px;
            left: -746px;
            height: 80px;
            line-height: 16px;
            font-size: 26px;
            color: #00066b;
            border: none;
            background-color: transparent;
            padding: 15px;
            padding-left: 70px;
            padding-right: 80px;
            height: 80px;
            @media (max-width: '1000px') {
                width: 90%;
                left: -546px;
            }
            &:focus-visible {
                outline: 2px solid $blue;
            }
            background-color: #FFFFFF;
        }
        input.tx-indexedsearch-searchbox-button {
            position: absolute;
            top: 26px;
            right: 23%;
            background-color: transparent;
            border: none;
            color: transparent;
        }
    }
    .tx-indexedsearch-rules {
        display: none;
    }
    &__container {
        .tx-indexedsearch-res {
            a {
                color: $blue;
            }
        }
    }
    ul.tx-indexedsearch-browsebox {
        list-style: none;
        display: inline-flex;
        li {
            border-right: 1px solid $blue;
            padding: 10px 15px;
            a {
                color: $blue;

            }
            &.tx-indexedsearch-browselist-currentPage a {
                color: $red;
            }

            &:first-child:not(.tx-indexedsearch-browselist-currentPage) {
                padding-top: 5px;
                padding-bottom: 5px;
                align-self: center;
                a {
                    color: transparent !important;
                    margin-top: 5px;
                    position: absolute;
                }
                &:before {
                    content: '\e808';
                    font-size: 20px;
                    font-family: "fontello";

                }
            }
            &:last-child {
                border: none;
                &:not(.tx-indexedsearch-browselist-currentPage) {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    align-self: center;
                    a {
                        color: transparent !important;
                        margin-top: 5px;
                        position: absolute;
                    }
                    &:after {
                        content: '\e809';
                        font-size: 20px;
                        font-family: "fontello";
                    }
                }

            }
        }
    }
    

}