.dak-breadcrumb {
    a {
        color: $blue;
    }

    .breadcrumb-item + .breadcrumb-item {
        &::before {
            font-size: 8px;
            line-height: 22px;
            font-family: "Fontello";
            content: "\e800";
        }
    }
}
