.powermail_form {
    background-color: $gray;
    fieldset {
        .powermail_fieldwrap {
            margin: 12px 0px;
            &_type_select .form-group{
                position: relative;
                width: 50%;
                line-height: 48px;
                select {
                    display: none;
                }
                .select-hide {
                    display: none;
                }
                .select-selected {
                    background-color: #FFF;
                    font-weight: 600;
                    color: $blue;
                    padding-right: 20px;
                    padding-left: 20px;
                    cursor: pointer;
                    text-align: left;
                    user-select: none;
                    &:after {
                        position: absolute;
                        content: "\e806";
                        font-family: 'fontello';
                        right: 18px;
                        top: 2px;
                        font-size: 12px;
                    }
                    &.select-arrow-active {
                        border: 1px solid $blue;

                        &:after {
                            content: "\e807";
                        }
                    }
                }
                .select-items {
                    position: absolute;
                    background-color: #FFF;
                    border: 1px solid $blue;
                    color: $blue;
                    top: 100%;
                    left: 0;
                    right: 0;
                    z-index: 99;
                    div {
                        cursor: pointer;
                        text-align: left;
                        user-select: none;
                        padding-right: 20px;
                        padding-left: 20px;
                        &:hover {
                            background-color: $greenShade;
                        }
                    }
                }
            }
        }
        .parsley-errors-list, .tx-srfeuserregister-pi1-error {
            color: $red;
            font-weight: 700;
            list-style: none;
            padding-left: 0px;
            padding-top: 10px;
            margin-bottom: 22px;
        }
        .powermail_label {
            display: none;
        }
        input.form-control, textarea {
            height: 48px;
            background-color: $white;
            border: none;
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 12px;
            color: $blue;
            font-weight: bold;
            &:focus {
                outline: $blue;
                border: 1px solid $blue;
            }
            &.parsley-error {
                    border: 1px solid $red;

            }
        }
        .powermail_textarea {
            padding-top: 15px;
            height: 190px;
            min-height: 56px !important;
        }
        .checkbox {
            label {
                margin-left: 35px;
            }
            .powermail_checkbox, input {
                position: absolute;
                margin-left: -35px;
                &::before {
                    width: 22px;
                    height: 22px;
                    background-color: $white;
                    display: block;
                    content: "";
                    float: left;
                    margin-right: 5px;
                    z-index: 5;
                    position: relative;
                    border: 2px solid $blue;
                }
                &::after {
                    content: "\e80b";
                    font-family: "fontello";
                    color: $blue;
                    position: absolute;
                    display: none;
                    left: 4px;
                    top: 1px;
                    z-index: 5;
                }
                &:checked::after {
                    display: block;
                }
            }
        }
        .radio label {
            margin-left: 35px;
            .powermail_radio {
                position: absolute;
                margin-left: -30px;
                margin-top: 3px;
                &::before {
                    width: 23px;
                    height: 23px;
                    background-color: $white;
                    display: block;
                    content: "";
                    float: left;
                    margin-right: 5px;
                    z-index: 5;
                    position: relative;
                    border: 2px solid $blue;
                    border-radius: 50%;
                    left: -5px;
                    top: -3px;
                }
                &::after {
                    content: "";
                    font-family: "fontello";
                    color: $blue;
                    position: absolute;
                    display: none;
                    top: 1px;
                    left: -1px;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: $blue;
                    z-index: 5;
                }
                &:checked::after {
                    display: block;
                }
            }
        }
        .powermail_submit {
            background-color: $petrol;
            border-radius: 0px;
            font-family: "Sofia Pro Bold";
            border: none;
            font-weight: 400;
            padding: 10px 30px;
            float: right;
            &:hover {
                background-color: $petrolShade;
            }
        }
    }
    .form-control:-webkit-autofill, .form-control:-webkit-autofill:hover, .form-control:-webkit-autofill:focus,  .form-control:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 40px $white inset !important;
    }
    .form-control:-webkit-autofill{
        -webkit-text-fill-color: $blue !important;
    }
}
.powermail_confirmation {
    input[type="submit"] {
        border-radius: 0px;
        font-family: "Sofia Pro Bold";
        border: none;
        font-weight: 400;
        padding: 10px 30px;
        float: right;
        font-size: 16px;
        margin: 10px;
        &.btn-primary {
            background-color: #05507b;
        }
    }
}
.form-control {
    font-size: 26px;
    color: $blue;
    border: none;
    background-color: $gray;
    padding: 15px;
    padding-right: 80px;
    height: 80px;
    width: 100%;
    &:focus {
        outline: none;
    }
    &:not(:placeholder-shown) + button[type="submit"] {
        opacity: 1 !important;
        color: $blue;
    }
    &::-webkit-search-cancel-button {
        display: none;
    }
}

.form-control--big {
    font-family: 'Sofia Pro Bold';
    font-weight: 700;
}

.dak-search {
    position: relative;
    display: flex;
    background-color: $gray;

    i {
        margin-top: 27px;
        margin-left: 30px;

        &::before {
            font-size: 30px;
            line-height: 30px;
        }
    }


}

.btn-secondary {
    color: $white !important;
    background-color: $petrol;
    border-radius: 0px;
    font-family: "Sofia Pro Bold";
    border: none;
    font-weight: 400;
    padding: 10px 30px;
    &:hover {
        background-color: $petrolShade;
    }
}

a.btn-secondary {
    color: $white !important;
}
