@media (max-width: map-get($grid-breakpoints, md)){
    .row > .col,
    .row > [class*="col-"] {
        padding-right: $grid-gutter-width-sm / 2;
        padding-left: $grid-gutter-width-sm / 2;
    }

    .row {
        margin-right: -#{$grid-gutter-width-sm / 2} !important;
        margin-left: -#{$grid-gutter-width-sm / 2} !important;
    }
}

@media (max-width: map-get($grid-breakpoints, md)){
    .container, .container-fluid, .container-sm {
        @include make-container($grid-gutter-width-sm);
    }
}
