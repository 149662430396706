// Color system

//== Brand colors
$red: #e2001a;
$petrol: #05507b;
$green: #d1ff7e;

//== Extra colors
$blue: #00066b;
$gray: #edf1f2;
$white: #ffffff;
$lightgray: #f7f7f7;
$muted: #6b6b6b;

//== Shades
$greenShade: #E3FFB2;
$petrolShade: #1E6188;
$grayShade: #E1E9EC;
$whiteShade: #F3F6F8;


$colors: (
    "dakosy-red": $red,
    "dakosy-petrol": $petrol,
    "dakosy-green": $green,
    "dakosy-blue": $blue,
    "dakosy-gray": $gray,
    "dakosy-white": $white,
    "dakosy-light-gray": $lightgray,
    "dakosy-muted": $muted,
    "dakosy-green-shade": $greenShade,
    "dakosy-petrol-shade": $petrolShade,
    "dakosy-gray-shade": $grayShade,
    "dakosy-white-shade": $whiteShade,
);

$breadcrumb-bg:                     $gray;
$breadcrumb-divider-color:          $blue;
$breadcrumb-active-color:           $red;
