.dak-teaser-card-container {

     & > .row {
         @include media-breakpoint-down(sm) {
             margin-right: 0px !important;
             margin-left: 0px !important;
         }
     }

    &__header,
    &__bodytext,
    &__lead-in {
        text-align: center;
    }
    &__slider {
        display: flex;
        .dak-teaser-card-item {
            margin-right: 30px;
            @include media-breakpoint-up(lg) {
                max-width: 41.6666666667% !important;
                flex: 0 0 41.6666666667% !important;
            }
            @include media-breakpoint-down(md) {
                max-width: 66.6666666667% !important;
                flex: 0 0 66.6666666667% !important;
                min-height: 600px;
            }
            @include media-breakpoint-down(sm) {
                max-width: 90% !important;
                flex: 0 0 90% !important;
                min-height: 380px;
            }
            &__content {
                position: absolute !important;
                bottom: 0 !important;
                width: 100% !important;
            }
        }
        &-header {
            max-width: 33.333333333%;
            flex: 0 0 33.333333333%;
            align-self: flex-end;
            flex-direction: column;
            .kicker {
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }
        @include media-breakpoint-down(md) {
           &-wrapper {
                margin: 20px auto;
            }
            .dak-teaser-card-item {
                &__image {
                    width: 100%;
                }
                > a {
                    display: block;
                }
            }
            &-header {
                flex: 0 0 58.3333333333%;
                max-width: 58.3333333333%;
            }
        }
        @include media-breakpoint-down(sm) {
            height: 380px;
            &-wrapper {
                margin-left: 0px;
                margin-right: 0px;
            }
            &-header {
                flex: 0 0 75%;
                max-width: 75%;
            }
        }
        article {
            padding-left: 0;
            padding-right: 0;
        }
        &-wrapper {
            overflow-y: hidden;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            i.dak-icon-arrow-right {
                cursor: pointer !important;
            }
            &::-webkit-scrollbar {
                height: 6px;
                background-color: $lightgray;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $petrol;
            }
            &.active {
                cursor: grabbing;
                cursor: -moz-grabbing;
                cursor: -webkit-grabbing;
            }
        }
    }
}
.dak-teaser-card-item>a {
    display: block;
    //width: 600px;
}
