@font-face {
    font-family: 'fontello';
    src: url('#{$font-path}DakIcons/fontello.eot');
    src: url('#{$font-path}DakIcons/fontello.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}DakIcons/fontello.woff2') format('woff2'),
         url('#{$font-path}DakIcons/fontello.woff') format('woff'),
         url('#{$font-path}DakIcons/fontello.ttf') format('truetype'),
         url('#{$font-path}DakIcons/fontello.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?39780848#fontello') format('svg');
  }
}
*/
[class^="dak-icon-"]:before, [class*=" dak-icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    font-size: 60px;
    speak: never;
  
    //display: inline-block;
    text-decoration: inherit;
    width: 1em;
    //margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
  
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
  
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
  
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    //margin-left: .2em;
  
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
  
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
  
  .dak-icon-caret:before { content: '\e800'; } /* '' */
  .dak-icon-arrow-down:before { content: '\e801'; } /* '' */
  .dak-icon-arrow-left:before { content: '\e802'; } /* '' */
  .dak-icon-arrow-right:before { content: '\e803'; } /* '' */
  .dak-icon-arrow-up:before { content: '\e804'; } /* '' */
  .dak-icon-plus:before { content: '\e805'; font-size: 35px;} /* '' */
  .dak-icon-chevron-down:before { content: '\e806'; } /* '' */
  .dak-icon-chevron-up:before { content: '\e807'; } /* '' */
  .dak-icon-chevron-left:before { content: '\e808'; } /* '' */
  .dak-icon-chevron-right:before { content: '\e809'; } /* '' */
  .dak-icon-search:before { content: '\e810'; } /* '' */
  .dak-icon-cross:before { content: '\e80b'; } /* '' */
  a {
    i.dak-icon-arrow-down, i.dak-icon-arrow-left, i.dak-icon-arrow-right, i.dak-icon-arrow-up {
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      text-decoration: none;
      i.dak-icon-arrow-right {
          margin-right: -10px;
          margin-left: 10px;
      }
      i.dak-icon-arrow-left {
          margin-left: -10px;
          margin-right: 10px;
      }
      i.dak-icon-arrow-up {
        margin-top: -10px;
        margin-bottom: 10px;
      }
      i.dak-icon-arrow-down {
        margin-bottom: -10px;
        margin-top: 10px;
      }
    }
  }
