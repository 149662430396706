.modal {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 99999;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0,0,0,0.6);
    opacity: 1;
    visibility: visible;
    &:not(.show) {
        visibility: hidden;
        opacity: 0;
        .modal-content {
            visibility: hidden;
            opacity: 0;
            -webkit-transform: scale(0.9);
            -moz-transform: scale(0.9);
            -ms-transform:scale(0.9);
            -o-transform:scale(0.9);
            transform: scale(0.9);
        }
    }
    .modal-content {
        opacity: 1;
        visibility: visible;
        background-color: #FFF;
        width: 1200px;
        @include media-breakpoint-down(md) {
            width: 600px;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
            max-height: 100vh;
        }
        max-height: 95vh;
        margin-top: 20px;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        .close {
            font-size: 2.5rem;
            padding-right: 20px;
            padding-top: 10px;
            color: $blue;
            opacity: 1;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .modal-body__wrapper {
        padding-right: 90px;
        padding-left: 90px;
        
    }
    .modal-body {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 80vh;
    padding-right: 30px;
        &::-webkit-scrollbar {
        width: 7px;
        background-color: $lightgray;
        overflow-x: hidden;
        }
        &::-webkit-scrollbar-thumb {
        background-color: $blue;
        }
    }
}